<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
#app{
  height:100vh;
  overflow: hidden;
}
@import "@/assets/scss/_reset.scss";
@import "@/assets/scss/_global.scss";
</style>
